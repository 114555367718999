@import "../../../styles/mixins";

.analytic {
  width: 100%;
  background-color: #1f2022;
  display: flex;
  font-family: "Lexend";
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 0px;
  transition: ease-in-out 400ms;
  padding-left: 2.8rem;
  padding-right: 2.8rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  * {
    margin: 0.8rem;
  }
  h3 {
    font-weight: 1;
    font-size: 48px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #a7a8aa;
  }

  p {
    font-weight: 200;
    font-size: 22px;
    text-align: center;
    color: #a7a8aa;
    opacity: 0.7;
  }
  border-radius: $border-radius;
}
