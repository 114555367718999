@import "../styles/mixins";

.sidebar {
  display: flex;
  //gap: 30px;
  //justify-content: space-between;
  width: 100%;
  height: 100%;

  .walletSidebar {
    order: 3;
    width: 100%;
    min-width: 300px;
    max-width: 300px;
    height: 100vh;

    //border-left: solid 1px $border-color;
    z-index: 999;

    @include tablet {
      display: none;
    }
  }

  .bodyContent {
    order: 2;
    display: flex;
    //width: calc(66% - 300px);
    // height: 100%;
    //margin: 0 auto;
    width: 100%;
    //padding: 0 40px;
    //margin-right: 0;
    //padding: 0 20px !important;
    margin-top: 0;
    padding-bottom: 50px !important;
    margin-bottom: 100px;
    //overflow: hidden scroll;
    //overflow: hidden;
    // overflow-x: hidden;

    @include tablet {
      // width: calc(100% - 100px);
      padding: 0 20px;
    }

    @include mobile {
      width: 100%;
      height: calc(100% - 50px);
      padding: 0 !important;
      //margin-top: 80px;
      //padding-bottom: 50px;
      overflow: initial;
    }
  }

  .sidebarCollapse {

    //width: 100px !important;
    /* span {
      display: none;
    } */
    button {
      img {
        display: block !important;
      }
    }
  }

  .navBar_credits {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex: 2;
    width: 100%;
    padding: 25px;
    @include mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 2;
      padding: 10px;
    }
  }

  .sidebarContent {
    order: 1;
    text-align: left;

    min-width: 300px;
    height: 100vh;

    //background-color: $secondarybg;
    //border-right: solid 1px $border-color;
    z-index: 999;

    @include tablet {
      min-width: 0px;
      width: 0px;
      span {
        display: none;
      }
    }

    @include mobile {
      min-width: 0 !important;
      width: 0% !important;
      border-right: none !important;
    }

    .navbar {
      width: 100%;
      min-width: 300px;
      max-width: 300px;
      // height: calc(100% - 50px);
      padding: 40px 20px;
      background-color: $secondarybg;
      border-right: $border;
      border-color: $border-color;
      margin: auto;
      position: fixed;
      display: flex;
      //align-items: center;

      // display: flex;
      // align-items: center;
      .mobileHumburgerMenu{
        display: none;
        .mobileMenu{
          display: none;
        }}

      @include tablet {
        width: 100%;
        min-width: 100px;
        max-width: 0px !important;
        height: 100%;
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          height: $mobileMenuHight;
          padding: 0;
          z-index: 999;
          border-top: solid 1px #ffffff20;
          .navBar_credits {
            b{
              display: block;
             font-size: 12px;
             margin-left: 2px;
            //  width: 60px;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 2;
            padding: 5px,
          }
          .sub_menu{
            display: none;
          }
          .mobileHumburgerMenu{
            display: block;
            .mobileMenu{
              display: none;
            }
            .mobileMenuActive{
                width: 40%;
                height: 100vh;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 0%;
                bottom: 50px;
                display: flex;
                flex-direction: column;
                align-content: flex-start;
                align-items: flex-start;
                justify-content: center;
                padding: 0px;
                .linksWapper{
                  height: 100%;
                  width: 100%;
                background-color: rgb(0, 0, 0);
                padding: 0px 20px;
  
                .menuHeading{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 40px 0px;
                margin-top: 150px;
                padding-bottom: 20px;
                text-transform: uppercase;
                border-bottom: 1px solid rgb(48, 48, 48);
                font-size: 20px;
                .headdding{
                  padding-left: 20px;
                  text-transform: uppercase;
                  font-size: 20px;
                  .closeIcon{
                    margin-left: -60px;
                  }
  
                }
                }
              }
            }
          }
          hr {
            display: none;
          }
  
          span {
            display: none;
          }
  
          .logo {
            display: none;
          }
  
          ul {
              margin-top: 0 !important;
              display: flex;
              align-items: center;
              width: 100%;
              padding: 0px 20px;
              gap: 50px;
              padding: 0px 100px;
              align-content: space-around;
              justify-content: center;            
          }
           ul li{
         padding: 0px !important;
           }
  
          li a {
            display: block !important;
            padding: 15px 0px !important;
            display: flex;
            justify-content: center;
            margin: 0px 14px;
            font-size: 16px;
          }
      }
     
      @include mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: $mobileMenuHight;
        padding: 0;
        z-index: 999;
        border-top: solid 1px #ffffff20;
        .navBar_credits {
          b{
            display: block;
           font-size: 12px;
           margin-left: 2px;
          //  width: 60px;
          }
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 2;
          padding: 5px,
        }
        .sub_menu{
          display: none;
        }
        .mobileHumburgerMenu{
          display: block;
          .mobileMenu{
            display: none;
          }
          .mobileMenuActive{
              width: 100%;
              height: 100vh;
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              left: 0%;
              bottom: 50px;
              display: flex;
              flex-direction: column;
              align-content: flex-start;
              align-items: flex-start;
              justify-content: center;
              padding: 0px;
              .linksWapper{
                height: 100%;
                width: 100%;
              background-color: rgb(0, 0, 0);
              padding: 0px 20px;

              .menuHeading{
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 40px 0px;
              margin-top: 150px;
              padding-bottom: 20px;
              text-transform: uppercase;
              border-bottom: 1px solid rgb(48, 48, 48);
              font-size: 20px;
              .headdding{
                padding-left: 20px;
                text-transform: uppercase;
                font-size: 20px;
                .closeIcon{
                  margin-left: -60px;
                }

              }
              }
            }
          }
        }
        hr {
          display: none;
        }

        span {
          display: none;
        }

        .logo {
          display: none;
        }

        ul {
          margin-top: 0 !important;
          display: flex;
          align-items: center;
          width: 100%;
          gap: 0px;
          padding: 0px 20px;          
        }
         ul li{
       padding: 0px !important;
         }

        li a {
          display: block !important;
          padding: 15px 0px !important;
          display: flex;
          justify-content: center;
          margin: 0px 14px;
          font-size: 16px;
        }
      }
    }

    .navbar_hm {
      @include mobile {
        position: inherit !important;
        display: none !important;
      }
    }

    .logo_section {
      display: flex;
      align-items: center;

      gap: 10px;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;

      color: #ffffff;

      .logo {
        width: 30px;
      }
    }

    .sub_menu_hide{
      display:  none;
    }
    .active .mainItem{
      padding: 14px 20px;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.08);
      display: flex;
      gap: 15px;
      .menuDropDown{
        margin-top: 6px;
      }

      @include tablet{
          background: none;
        .menuDropDown{
          display: none;
        }
      }
      @include mobile {
        padding: 0 20px;
        border-radius: 12px 12px 0 0;
        background-color: transparent;
        .menuDropDown{
          display: none;
        }
      }
    }

    .sub_menu li a:hover {
      color: #9b83f9 !important;
    }

    .active_sub a {
      color: #8164F5;
    }

    /* ul {
      margin-top: 50px;
    } */

    li {
      padding: 14px 20px;
      margin: 10px 0;

      @include mobile {
        padding: 0 20px;
      }
    }

    li a {
      display: flex;
      align-items: center;
      // margin-top: 40px;

      gap: 20px;
      //padding: 25px 0;
      font-weight: 500;
      // font-size: 22px;
      line-height: 18px;

      color: #C5C6CA;
  
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.668);
      z-index: -2;
      backdrop-filter: blur(10px);
    }

    .contentDrawer {
      position: absolute;
      top: 0;
      left: 0;
      padding: 40px;

      width: 100%;
      min-width: 400px;
      height: 100vh;
      background: $secondarybg;
      border-right: solid 1px $border-color;
      border-left: solid 1px $border-color;
      z-index: -1;
      overflow: hidden;
      transition: ease-in-out 0ms;

      @include mobile {
        width: 100%;
        top: 70px;
        left: 0 !important;
        bottom: 0 !important;
        transition: ease-in-out 0ms !important;
      }

      .close {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        color: #F6F5FF;
      }
    }

    position: relative;

    @include mobile {
      position: inherit;
    }

    .footer {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      width: 100%;

      margin: auto;
      padding: 0 20px !important;

      @include mobile {
        display: none;
        visibility: hidden;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;

        img {
          display: none;

          @include tablet {
            display: block;
          }
        }
      }
    }
  }
}