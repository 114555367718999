@import "../styles/mixins";

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  flex-shrink: 0;

  border-bottom: 1px solid $border-color;
  background: $secondarybg;
  padding: 0 10px;
  z-index: 9999;

  @include mobile {
    //display: none;
    position: fixed;
    z-index: 9999;
  }

  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 100%;
    padding: 15px 20px;

    .left {
      display: flex;
      align-items: center;

      .nav {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-left: 240px;

        @include tablet {
          margin-left: 40px !important;
          gap: 15px;
        }

        @include mobile {
          display: none;
        }

        a {
          
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #C5C6CA !important;
          text-decoration: none;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 0;
            height: 2px;
            background: #C5C6CA;
            transition: all 200ms ease-in-out;
          }
          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
      }

    }

    .right {
      display: flex;
      align-items: center;

      .profile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        width: 220px;
        min-width: 220px;
        padding: 8px 14px;

        border-radius: 50px;
        background: rgba(255, 255, 255, 0.05);
        position: relative;
        cursor: pointer;
        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
        p {
          color: #F6F5FF;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }

      .loginButton {
        width: auto;
        min-width: 150px;
        padding: 8px 14px;
        margin: 0 10px;
        border-radius: 50px;

        @include mobile {
          min-width: 100px;
        }
      }

      .signupButton {
        width: auto;
        min-width: 150px;
        padding: 8px 14px;

        color: #fff;
        background: $primary;
        border-color: $primary;
        border-radius: 50px;

        @include mobile {
          min-width: 100px;
        }

        &:hover {
          color: #fff;
          background: #8168e1 !important;
          border-color: $primary;
        
        }
      }
    }
  }

  .logo_section {
    display: grid;
    text-align: center;
    width: auto;

    gap: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;

    color: #ffffff;

    .logo {
      width: 50px;
      span {
        font-size: 14px;
      }
    }
  }

  .dropdown {
    display: flex;
    position: relative;
    img {
      width: 20px !important;
      height: 20px !important;
      flex-shrink: 0;
    }
  }
  .dropdown_content {
    display: block;
    position: absolute;
    top: 50px;
    right: 0;

    width: 220px;
    min-width: 220px;
    background: rgb(41 42 47);
    padding: 20px 14px;

    border-radius: 0 0 25px 25px;

    a {
      display: block;
      color: #F6F5FF;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      padding: 8px 0;

      &:hover {
        color: $primary !important;
      }
    }
  }
}