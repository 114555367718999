@import "../../../styles/mixins";

.login_modal {
  .logo {
    width: 72px;
  }

  h3 {
    font-size: 18px;

    margin-top: 20px;
  }

  .list {
    width: 80%;
    margin: 20px  auto;

    p {
      display: flex;
      text-align: left;
      padding: 4px 0;

      img {
        margin-right: 10px;
      }
    }
  
  }
}

.button {
  background: linear-gradient(96.68deg, #62D9EC 0%, #9469E0 105.7%), #F6F5FF;
  width: 50%;
  height: 77px;
  border-radius: 12px;

  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fff;

  &:disabled {
    opacity: 0.5;
  }
}

.content {
  display: block;

  width: 400px;
  //min-height: 200px;
  margin-top: 40px;
  margin: auto;
}