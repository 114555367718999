@import "../../../styles/mixins";

.welcomeCard {
  width: 100%;
  background-image: linear-gradient(to bottom, #3a2151, #06020c) !important;
  display: flex;
  font-family: "Lexend";
  flex-direction: column;
  justify-content: space-between;

  border: 0px;
  transition: ease-in-out 400ms;

  * {
    margin: 0.8rem;
  }
  h3 {
    font-weight: 1;
    font-size: 48px;

    letter-spacing: -0.03em;
    color: #a7a8aa;
  }

  p {
    font-weight: 200;
    //font-size: 22px;

    color: #a7a8aa;
    opacity: 0.7;
  }
  border-radius: $border-radius;
  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 1;
      align-self: flex-start;
      font-size: 20px;
      text-align: left;
      letter-spacing: -0.03em;
      color: #a7a8aa;
    }
    img {
      height: 2rem;
    }
  }
}
.card:hover{
  border: 2px solid purple;
  background-image: linear-gradient(to bottom, #3a2151, #06020c) !important;
}
.card {
  width: 100%;
  background-color: #1f2022;
  display: flex;
  font-family: "Lexend";
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 0px;
  transition: ease-in-out 400ms;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  img {
    height: 3rem;
  }
  * {
    margin: 0.8rem;
  }
  h3 {
    font-weight: 1;
    font-size: 48px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #a7a8aa;
  }

  p {
    font-weight: 200;
    font-size: 22px;
    text-align: center;
    color: #a7a8aa;
    opacity: 0.7;
  }
  border-radius: $border-radius;
}
