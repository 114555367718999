@import "../../../styles/mixins";

.wallet_login_wrapper {
  margin: auto;

  .divider {
    margin: 10px 0 15px 0;
    span {
      font-size: 18px;
      font-weight: 600 ;
    }
  }

  .signup {
    width: 280px;
    height: 57px;
    border-color: #fff !important;
    color: #fff !important;

    &:hover {
      border-color: transparent !important;
    }

    @include mobile {
      width: auto;
      min-width: 100px !important;
    }
  }
  
  .loginbtn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.mainLogin{
  display: flex;
  justify-content: center;
  grid-gap: 25px;
  gap: 25px;
  margin: 100px;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;

  
.loginButtonPage {
  width: auto;
  min-width: 150px;
  padding: 8px 14px;
  margin: 0 10px;
  border-radius: 50px;

  @include mobile {
    min-width: 100px;
  }
}

.signupButtonPage {
  width: auto;
  min-width: 150px;
  padding: 8px 14px;

  color: #fff;
  background: $primary;
  border-color: $primary;
  border-radius: 50px;

  @include mobile {
    min-width: 100px;
  }

  &:hover {
    color: #fff;
    background: #8168e1 !important;
    border-color: $primary;
  
  }
}

}