@import "../../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");

.container {
  width: 100%;
  padding: 25px;
  margin: 10px;

  button {
    background-color: transparent;
    font-weight: 700;
    color: #fff;
    border: solid 2px #9b83f9;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  button:hover {
    background-color: #9b83f9;
  }

  .form_step_info .heading_with_button {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }

  .heading {
    padding: 10px 0px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 0;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff0fa;

    @include mobile {
      padding: 10px;
      font-size: 38px;
      line-height: 24px;
    }
  }
  .subHeading {
    padding: 10px 0px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff;
    opacity: 0.7;
  }

  .tableContainer {
    width: 100%;
    overflow-x: auto;
    height: 350px;

    .noDataContainer{
      margin-top: 0px !important;
      display: flex;
      width: 100%;
      height: 200px;
      justify-content: center;
      background-color: #1f2022;
      align-content: center;
      align-items: center;
      background-color: #1f2022;
      h1{
          text-align: center;
        }
      }
      .table {
        background-color: #1f2022;
        border-radius: $border-radius;
        border-collapse: collapse;

         thead {
          position: sticky;
          top: 0;
          z-index: 1;
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;

          th {
            &:first-child {
              border-top-left-radius: $border-radius;
            }

            &:last-child {
              border-top-right-radius: $border-radius;
            }

            background-color: #2d2d2f;
            font-weight: 400;
            // line-height: 15px;
            text-align: left;
            // letter-spacing: 0.06em;
            padding: 12px;
          }
        }

        tbody {
          .activeRow {
            cursor: pointer;
            background-color: #2d2d2f;
          }
          tr:hover {
            cursor: pointer;
            background-color: #2d2d2f;
          }
           tr {
            
            td {
              padding: 18px;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #c1c1c3;
              font-family: sans-serif;
            }

            .list_action_button {
              cursor: pointer;
              position: relative; // Ensure the button is positioned relative
              font-weight: 700;
              font-size: 18px;
            }

            .popover {
              position: absolute;
              top: calc(100% + -10px);
              right: 5px;
              background-color: #2d2d2f;
              border-radius: 5px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              width: 100px;
              z-index: 1;
            }

            .popover button {
              display: block;
              width: 100%;
              padding: 10px 20px;
              font-weight: 400;
              font-size: 12px;
              text-align: left;
              border-bottom: 1px solid #2d2d2f;
              background-color: transparent;
              cursor: pointer;
              border-radius: 5px;
            }

            .popover button:hover {
              background-color: #3d3d40;
              border-radius: 5px;
            }
          }
        }
      }

  }
 
  @include mobile {
    padding: 10px !important;
    margin-top: 30px;
  }

  @include tablet {
    padding: 20px;
  }

  .innerContainer {
    padding-right: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    .create_button {
      margin: 50px 0px 10px 0px;
        button {
          padding: 5px 25px 8px 25px;
        }
    }

    .row {
      width: 100%;
      justify-content: space-between;
    }


      .subHeading {
        padding: 20px 0px 20px 0px;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: #fff;
        opacity: 0.7;

        @include mobile {
          padding: 20px 0px;
        }
      }

      .content {
        padding: 0 !important;
      }

      // height: 400px;
     
    }

  .singleViewContainer{
    padding: 40px;
    margin-left: 40px;
    h1{
      font-weight: 700;

    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .col {
    flex: 1;
    padding: 10px;
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  h3 {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 0;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff0fa;

    @include mobile {
      padding: 0;
      font-size: 38px;
      line-height: 24px;
    }
  }



  .info {
    padding: 10px 0px;
    margin-top: 10px;

    @include mobile {
      font-size: 12px;
    }
  }
}

.progress_container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 40px;
  .buttons {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    button:disabled {
    border: solid 2px #86868f;
    background-color: transparent;
    }
  }
}

.editable_field{
  border-radius: 5px;

}

.view_input_container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2d2d2f;
  padding-right: 10px;
  border-radius: 10px;
  img{
    margin-left: 5px;
    cursor: pointer;
  }
  input{
    margin-top: 5px;
  }
  .save_button {
    padding: 5px 20px;
    background-color: transparent;
    font-weight: 700;
    color: #fff;
    border: solid 2px #887dea;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
}


.view_bar {
  .update_top{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 100px;
    p {
      padding: 12px 60px;
      background: #2d2d2f;
      margin: 10px 0px;
      border-radius: 5px;

      span {
        font-weight: 500;
      }
    }
    .button_div{
      align-self: center;
      .view_button {
      padding: 10px 60px;
      background-color: transparent;
      font-weight: 700;
      color: #fff;
      border: solid 2px #887dea;
      border-radius: 20px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    }
  }
}

@media screen and (min-width: 768px) {
  .progress_container {
    display: flex;

  }

  .progress_bar_container {
    width: 100%;
    // margin-bottom: 20px;
  }
}

.progress_bar_container {
  flex: 1;
  background-color: #2d2d2f;
  border-radius: 5px;
  padding: 2px;
}

.progress_bar {
  height: 5px;
  background-color: #4def9c;
  border-radius: 5px;
  transition: width 0.5s;
}

.form_container {
  .form {
    background-color: transparent;

  }

  .sub_info {
    margin: 20px 0px;
  }

  .form_step {
    padding: 2% 0% 2%;
    margin-bottom: 20px;
    margin-top: 20px;

    .step_info {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .step_sub_info {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .category {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .post_card {
      padding: 10px;
      width: 300px;
      display: flex;
      align-items: center;
      margin: 0px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border-radius: 5px;
      &:hover {
        background-color: #2d2d2f;
      }

      &.active {
        background-color: #2d2d2f;
        color: #fff;
        border: solid 2px #887dea;
      }

      // .category_image_box {
      //   padding: 5px 5px;
      // }

      img {
        width: 60px;
        height: 60px;
        margin-right: 20px;
        object-fit: contain;
        box-shadow: 0 0 5px #887dea;
      }

      h1 {
        font-size: 14px;
        font-weight: 700;
        margin-top: 0px;
      }

      p {
        margin: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #86868f;
      }
    }
  }

  .post_grid {
    // display: flex;
    justify-content: center;
    margin: 50px;
    background-color: #1f2022;
    box-shadow: 0 0 30px #1f2022;
    //padding: 2% 8%;
    padding-top: 10px;
    border-radius: 10px;

    
    .preview_info {
      font-size: 18px;
      text-align: center;
      margin-bottom: 10px;
    }

    select {
      width: 180px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      background-color: #515154;
      border: none;
      padding: 10px;
      border-radius: 5px;
    }

    .grid_row {
      margin: 20px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;

      .grid_cal {
        width: calc(33.33% - 5px);
        margin-bottom: 20px;
      }

      .grid_card {
        margin: 10px;
        border-radius: 5px;
        overflow: hidden;
        background: linear-gradient(90deg, rgb(255, 215, 0), rgb(255, 165, 0), rgb(255, 215, 0));
        box-shadow: rgb(255, 215, 0) 0px 1px 15px 0px, rgba(0, 0, 0, 0) 0px 0px 10px 1px;
        border: 10px solid rgb(255, 215, 0);
        overflow: hidden;
        position: relative;
        cursor: pointer;
      }

      img {
        width: 220px;
        height: 220px;
        background: linear-gradient(90deg, rgb(255, 215, 0), rgb(255, 165, 0), rgb(255, 215, 0));
        box-shadow: rgb(255, 215, 0) 0px 1px 15px 0px, rgba(0, 0, 0, 0) 0px 0px 10px 1px;
        border: 10px solid rgb(255, 215, 0);

      }

      .active_card {
        margin-bottom: 20px;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: inset 0 0 10px 0 rgba(45, 45, 47, 0.5), inset 0 0 10px 0 rgba(136, 125, 234, 0.5), inset 0 0 10px 0 yellow;
        overflow: hidden;
        position: relative;
        cursor: pointer;
      }

      .grid_card_img_top {
        width: 100%;
        height: auto;
        object-fit: contain;
      }

      .grid_card_title {
        font-size: 14px;
        position: absolute;
        top: 10px;
        left: 10px;
        color: #fff;
      }

      .grid_card_text {
        font-size: 14px;
        font-weight: 400;
        position: absolute;
        bottom: 10px;
        left: 10px;
        color: #fff;
      }

      @media screen and (min-width: 768px) {
        .grid_cal {
          width: calc(25% - 10px);
        }
      }
    }
  }

  .confirm_button {
    text-align: center;

    button {
      margin-top: 40px;
      padding: 5px 25px 5px 25px;
      background-color: transparent;
      font-weight: 700;
      color: #fff;
      border: solid 2px #887dea;
      border-radius: 20px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
  }

  .preview_cal_btn {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    button{
      padding: 5px 25px 8px 25px;
      border-radius: 40px;
    }
  }

  .preview_row {
    // margin-top: 25px;
    display: flex;

    .preview_cal {
      width: 50%;
    }

    .preview_input {
      padding-right: 50px;

      p {
        padding: 12px 25px;
        background: #2d2d2f;
        margin: 10px 0px;
        border-radius: 10px;

        span {
          font-weight: 500;
        }
      }
    }

    @media screen and (min-width: 768px) {
      .preview_cal {
        width: 100%;
      }
    }

    .preview_post_info {
      margin-top: 10px;
      font-size: 15px;
      margin-bottom: 20px;
    }

    .preview_post {
      text-align: center;
      align-self: center;
      border-radius: 5px;
 .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(358deg, #000, transparent);
      transition: ease-in-out 400ms;
      box-shadow: 0 1px 50px 0 rgb(113, 25, 181), 0 0 10px 1px rgba(0, 0, 0, 0) !important;
    }
    
    .creator_type {
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      top: 20px;
      right: 20px;
      border-radius: 4px;
      padding: 3px 8px;
      border: 1px solid #3B395A;
      background: #151331;

      span {
        color: $secondary;
        /* background: linear-gradient(258deg, var(--purple), var(--blue));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
      }
    }

    @include mobile {
      width: 100%;
    }

    .club_details {
      width: 100%;
      position: absolute;
      bottom: -100px;
      left: 0;
      
      height: 180px !important;

      border-radius: $border-radius $border-radius 0 0;
      overflow: hidden !important;
      transition: ease-in-out 400ms;

      @include tablet {
        bottom: -80px;
        .details {
          opacity: 1 !important;
        }
        .content {
          opacity: 0 !important;
        }
      }

      @include mobile {
        .details {
          opacity: 1 !important;
        }
        .content {
          opacity: 0 !important;
        }
      }

      .content {
        opacity: 0;
        font-weight: 400 !important;
        font-size: 13px !important;
        line-height: 18px !important;
        color: #999CB6 !important;
        transition: ease-in-out 400ms;
      }

      .details {
        //opacity: 0;
        padding: 20px;
        transition: ease-in-out 400ms;
      }

      p {
        color: #fff;
        font-size: 18px !important;
        font-weight: 700;
        text-align: left;

        padding: 0 10px;
        margin-top: 10px;

        b {
          font-size: 18px !important;
          color: #fff;
          font-weight: bold;
        }
      }
    }

      .grid_card {
        margin-bottom: 20px;
        border-radius: 5px;
        overflow: hidden;
        background: linear-gradient(90deg, #2d2d2f, #887dea, yellow);
      box-shadow:  0 0 10px rgba(171, 255, 3, 0.5);
        overflow: hidden;
        position: relative;
        cursor: pointer;

        img {
          width: 350px;
          height: 350px;
          margin-bottom: -7px;
        }
      }

    }
  }

  .form_row {
    display: grid;
    display: flex;
    gap: 10px;
  }

  .form_col {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
  }

  .form_col50 {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: space-around;
  }

  .input_row {
    flex-direction: row;
    gap: 20px;
  }

  .labelThumbnail {
    margin: 10px;
  }

  label {
    margin-bottom: 5px;
  }

  input[type="text"],
  input[type="number"],
  textarea,
  input[type="date"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #2d2d2f;
    background: #2d2d2f;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .thumbnail_preview {
    margin-top: 10px;
  }

  .thumbnail_preview img {
    max-width: 100%;
    height: auto;
  }

  .label {
    margin-bottom: 5px;
  }

  .input_field {
    width: calc(100% - 110px);
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .message {
    margin-top: 15px;
    background-color: #2d2d2f;
    text-align: center;
    padding: 10px;
  }

  button {
    margin-left: 10px;
    padding: 1px 20px 2px 20px;
    background-color: transparent;
    color: #fff;
    border: 2px solid #887dea;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
}


.toast {
  position: fixed;
  top: 80px;
  right: 40px;
  background-color: #333;
  color: #fff;
  padding: 8px 20px;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.grid_card {
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
  background: linear-gradient(90deg, rgb(255, 215, 0), rgb(255, 165, 0), rgb(255, 215, 0));
  box-shadow: rgb(255, 215, 0) 0px 1px 15px 0px, rgba(0, 0, 0, 0) 0px 0px 10px 1px;
  border: 1px solid rgb(255, 215, 0);
  overflow: hidden;
  position: relative;
  cursor: pointer;
}