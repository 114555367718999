@import "../../../../styles/mixins.scss";

.zonehubs {
  width: 100%;
  padding: 25px;

  .heading {
    padding: 25px 15px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 0;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff0fa;

    @include mobile {
      padding: 10px;
      font-size: 38px;
      line-height: 24px;
    }
  }
.ads_main{
  margin-left: 15px;
  display: flex;
  background-color: #2d2d2f;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  margin-top: 25px;
  padding: 50px;
}
 .codeContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    background-color: #2d2d2f;
    padding: 10px;
    width: 330px;
    height: 330px;
    box-shadow: 0 1px 15px 0 #ffd700, 0 0 10px 1px rgba(0, 0, 0, 0);
  button {
    padding: 10px 40px;
    background-color: transparent;
    color: #fff;
    border: 2px solid #887dea;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .ad_Style{
    width:300px; height:600px; overflow:hidden; border:none;
  }
  
}
.message {
  margin: 25px;
  background-color: #2d2d2f;
  text-align: center;
  padding: 25px;
}

  @include mobile {
    padding: 10px !important;
    margin-top: 30px;
  }

  @include tablet {
    padding: 20px;
  }
}