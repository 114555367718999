@import "../../../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");

.payment_card {
  width: 480px;
  position: absolute;
  z-index: 99;
  .popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }

  .popup {
    margin-top: 25px;
    background-color: #ffffff;
    width: 300px;
    border-radius: 5px;
    position: relative;
    color: #2d2d2f;

    .popup_head {
      padding: 50px 50px 10px 50px;
      text-align: center;
      background: #e6e6e7;
      border-radius: 5px 5px 0px 0px;

      img {
        width: 70px;
        height: 70px;
        position: absolute;
        top: -35px;
        left: 38%;
        border-radius: 50%;
        border: 3px solid #fff;
        box-shadow: 0 0 3px #2d2d2f inset;
        background: #2d2d2f;
        object-fit: contain;
      }

      .heading {
        font-size: 18px;
        font-weight: 700;
      }

      .subheading {
        font-size: 14px;
        // font-weight: 500;
        color: #87878f;
      }
    }

    .close_btn {
      font-size: 10px;
      font-weight: 700;
      position: absolute;
      top: 10px;
      left: 10px;
      box-shadow: 0 0 4px #2d2d2f inset;
      color: #fff;
      background: #aaaaaf;
      border: none;
      cursor: pointer;
      padding: 2.5px 5px;
      width: 20px;
      border-radius: 50%;
    }
  }

  .checkout_button {
    button {
      padding: 5px 25px 5px 25px;
      background-color: transparent;
      font-weight: 700;
      color: #fff;
      background-color:  #9b83f9;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
      width: 100%;
    }
  }

  .popup_content {
    overflow-y: auto;
    padding: 30px;
    box-shadow: 0 0 2px #2d2d2f inset;

    .checkbox_wrapper {
      margin: 20px 0px;
      display: flex;
      padding: 5px 8px;
      border-radius: 3px;
      box-shadow: 0 0 2px #2d2d2f inset;
      background-color: transparent;

      label {
        display: inherit;

        input[type="checkbox"] {
          margin-top: 3px;
        }

        p {
          margin-left: 10px;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    .stripe_card {
      .input_row_flex {
        margin-bottom: 25px;
        display: flex;
        position: relative;

        svg {
          position: absolute;
          fill: #3ca5e6;
          font-size: 14px;
          top: 8px;
          left: 10px;
        }

        .input_wrapper {
          width: 50%;
          margin-top: 0px;
          position: relative;

          .cardExpiry {
            padding: 8px 5px 8px 35px;
            border-radius: 0px 0px 0px 3px;
            box-shadow: 0 0 2px #2d2d2f inset;
            position: relative;
          }

          .cardCvc {
            position: relative;
            padding: 8px 5px 8px 35px;
            border-radius: 0px 0px 3px 0px;
            box-shadow: 0 0 2px #2d2d2f inset;

            svg {
              position: absolute;
              fill: #3ca5e6;
              font-size: 15px;
              top: 8px;
              left: 10px;
            }
          }
        }
      }

      .input_row {
        display: flex;
        justify-content: space-between;

        .input_wrapper_100 {
          width: 100%;
          margin-top: 10px;
          position: relative;

          .CardBrandIcon-inner {
            svg {
              fill: #3ca5e6 !important;
            }
          }

          svg {
            position: absolute;
            fill: #3ca5e6;
            font-size: 18px;
            top: 12px;
            left: 10px;
          }

          .email_input {
            width: 100%;
            padding: 8px 35px;
            border-radius: 3px 0px 3px 0px;
            box-shadow: 0 0 2px #2d2d2f inset;
          }

          .card_Number {
            padding: 8px;
            border-radius: 3px 3px 0px 0px;
            box-shadow: 0 0 2px #2d2d2f inset;

            .ElementsApp .Icon-fill {
              fill: #009dff;
            }

            svg {
              fill: #3ca5e6 !important;
            }
          }
        }
      }
    }
  }
  .price_input{
    width: 100%;
    padding: 8px 15px;
    border-radius: 3px 0px 3px 0px;
    box-shadow: 0 0 2px #2d2d2f inset;
  }

  @media (max-width: 768px) {
    .popup {
      width: 90%;
      height: auto;
      /* Adjust height as needed */
    }
  }
}

.toast {
  position: fixed;
  top: 80px;
  right: 40px;
  background-color: #333;
  color: #fff;
  padding: 8px 20px;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}


.stripe_laoder {
  color: #4c4969;
  text-indent: -9999em;
  margin: 35px auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.stripe_laoder, .stripe_laoder:before, .stripe_laoder:after {
  background: #4c4969;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
