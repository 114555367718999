@import "../../../../styles/mixins.scss";

.promotionhubs {
  width: 100%;
  padding: 25px;

  .heading {
    padding: 20px 10px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 0;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff0fa;

    @include mobile {
      padding: 10px;
      font-size: 38px;
      line-height: 24px;
    }
  }
  .subHeading {
    margin-top: 10px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff;
    opacity: 0.7;
  }

  @include mobile {
    padding: 10px !important;
    margin-top: 30px;
  }

  @include tablet {
    padding: 20px;
  }

  .analyticsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;

    .row {
      width: 100%;
      justify-content: space-between;
    }
  }

  .tableContainer {
    width: 100%;
    overflow-x: auto;
    height: 350px;

    .table thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
    
    .noDataContainer{
    margin-top: 0px !important;
    display: flex;
    width: 100%;
    height: 200px;
    justify-content: center;
    background-color: #1f2022;
    align-content: center;
    align-items: center;
    background-color: #1f2022;
    h1{
        text-align: center;
      }
    }
  }

  .topCampaignsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;

    .table {
      background-color: #1f2022;
      border-radius: $border-radius;
      border-collapse: collapse;

      thead {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        th {
          &:first-child {
            border-top-left-radius: $border-radius;
          }

          &:last-child {
            border-top-right-radius: $border-radius;
          }

          background-color: #2d2d2f;
          font-weight: 500;
          line-height: 15px;
          text-align: left;
          letter-spacing: 0.06em;
          text-transform: uppercase;
          padding: 18px;
        }
      }

      tbody {
        tr {
          td {
            padding: 18px;
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
          }
        }
      }
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .col {
    flex: 1;
    padding: 10px;
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }
  .content {
    padding: 0 !important;
  }
}
.chart_container{
width: 100%;

.dropdown_container {
  display: flex; 
  justify-content: flex-start;
  gap: 30px;
  margin-left: 30px;
}

.dropdown {
  padding: 10px 24px 10px 12px; 
  font-size: 16px;
  border: 1px solid #2d2d2f; 
  border-radius: 4px; 
  background-color: #2d2d2f; 
  cursor: pointer; 
  width: 200px; 
  margin-bottom: 20px;

  .dropdown_option{
    padding: 20px !important;
  }
}
}